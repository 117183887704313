<template>
  <v-container>
    <invoice-table v-bind="props" :update-route="true"></invoice-table>
  </v-container>
</template>
<script>

import InvoiceTable from '@/components/InvoiceTable'
import {invoiceTable} from '@/tables'

export default {
  components: {
    InvoiceTable
  },
  data () {
    return {
      props: invoiceTable
    }
  }
}
</script>
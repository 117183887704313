<template>
  <div>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Description
            </th>
            <th class="text-left">
              Quantity
            </th>
            <th class="text-left">
              VAT
            </th>
            <th class="text-left">
              Unit Price
            </th>
            <th class="text-left">
              Gross amount
            </th>
            <th class="text-left" v-if="!disabled"></th>
          </tr>
        </thead>
        <tbody ref="rows">
          <tr
            v-for="(item, idx) in fixedRows"
            :key="idx"
          >
            <td>
              {{ item.label }}
            </td>
            <td>
              {{ item.quantity }}
            </td>
            <td>
              {{ Number(item.vat_rate).toFixed(2) }}
            </td>
            <td>
              {{ Number(item.excluding_tax_price).toFixed(3) }}
            </td>
            <td>
              {{ (item.excluding_tax_price * item.quantity).toFixed(2) }} €
            </td>
            <td v-if="!disabled"></td>
          </tr>
          <tr
            v-for="(item, idx) in model"
            :key="idx"
          >
            <td>
              <v-text-field
                v-model="item.label"
                :disabled="disabled"
              >
              </v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.quantity"
                :disabled="disabled"
                type="number"
                step="1"
                min="1"
              >
              </v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.vat_rate"
                type="number"
                step="0.01"
                min="0"
                :disabled="disabled"
              >
              </v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.excluding_tax_price"
                :disabled="disabled"
                type="number"
                step="0.01"
                suffix="€"
              >
              </v-text-field>
            </td>
            <td>
              {{ (item.excluding_tax_price * item.quantity).toFixed(2) }} €
            </td>
            <td v-if="!disabled">
              <v-btn
                icon
                x-small
                @click.prevent="model.splice(idx, 1)"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      small
      @click.prevent="addRow">
      Add row
    </v-btn>
  </div>
</template>
<script>

function getAmounts(rows) {
  let grossAmount = 0
  let taxAmount = 0
  rows.forEach(r => {
    let ga = r.excluding_tax_price * r.quantity
    grossAmount += ga
    taxAmount += ga * r.vat_rate
  })
  return [grossAmount, taxAmount]
}
export default {
  props: ["value", "disabled", "fixedRows"],
  data () {
    return {
      model: this.value,
    }
  },
  methods: {
    addRow () {
      this.model.push({
        vat_rate: 0.2,
        quantity: 1,
        label: `Row ${this.model.length + 1}`,
        excluding_tax_price: 1
      })
      this.$nextTick(() => {
        if (!this.$refs.rows) {
          return
        }
        let rows = this.$refs.rows.getElementsByTagName('tr')
        rows[rows.length - 1].getElementsByTagName('input')[0].focus()
      })
    }
  },
  watch: {
    model: {
      handler (v) {
        this.$emit('input', v)
        this.$emit('amounts', getAmounts(v.concat(this.fixedRows || [])))
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>
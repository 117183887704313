<template>
  <v-data-table
    :headers="headers"
    :items="results.results"
    :options.sync="options"
    :server-items-length="results.count"
    v-if="results"
    :loading="isLoading"
    :footer-props="{itemsPerPageOptions: [5, 25, 50, 100]}"
    @update:options="options = options"
  >
    <template v-slot:item.id="{ item }">
      <router-link :to="{name: `${routerNamespace}.detail`, params: {id: item.id}}">
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.client.name="{ item }">
      <router-link :to="{name: `clients.detail`, params: {id: item.client.id}}">
        {{ item.client.name }}
      </router-link>
    </template>
    <template v-slot:item.is_draft="{ item }">
      <v-simple-checkbox
        v-model="item.is_draft"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.is_paid="{ item }">
      <v-simple-checkbox
        v-model="item.is_paid"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:top>
      <data-table-top
        :form-title="formTitle"
        :item="editedItem"
        :entity-name="entityName"
        :entity-name-plural="entityNamePlural"
        :can-create="canCreate"
        :can-edit="canEdit(item)"
        :no-save="!canSave(item)"
        :query.sync="filters.q"
        @save="save($event)"
        @close="close()"
        ref="top"
      >
        <template v-slot:modalForm="slotProps">
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="slotProps.item.public_id"
                label="Reference"
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-checkbox
                v-model="slotProps.item.is_draft"
                label="Draft"
                :disabled="!canSave(slotProps.item)"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="4"
            >
              <v-select
                v-model="slotProps.item.currency"
                :items="[{code: 'USD', name: 'US Dollars'}, {code: 'EUR', name: 'Euros'}]"
                item-text="name"
                item-value="code"
                label="Currency"
                :error-messages="itemErrors.fieldErrors.currency || []"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <date-field
                label="Date"
                v-model="slotProps.item.date"
                :disabled="!canSave(slotProps.item)"
                :error-messages="itemErrors.fieldErrors.date || []">
              </date-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                :value="Number(slotProps.item.excluding_tax_amount || 0).toFixed(2)"
                label="Gross amount"
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                :value="Number(slotProps.item.tax_amount || 0).toFixed(2)"
                label="Tax amount"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <invoice-rows
                outlined
                v-model="slotProps.item.free_rows"
                :fixed-rows="slotProps.item.usage_rows"
                @amounts="slotProps.item.excluding_tax_amount = $event[0];slotProps.item.tax_amount = $event[1]"
                :disabled="!canSave(slotProps.item)">
              </invoice-rows>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                outlined
                v-model="slotProps.item.notes"
                :disabled="!canSave(slotProps.item)"
                label="Notes">
              </v-textarea>
            </v-col>
          </v-row>
        </template>
      </data-table-top>
    </template>
    <template v-slot:item.actions="{ item }" v-if="canEdit(item) || canDestroy(item)">
      <v-icon
        small
        v-if="canEdit(item)"
        class="mr-2"
        @click="editItem(item)"
      >
        {{ $icons.mdiPencil }}
      </v-icon>
      <download-link
        :api-url="`admin/invoices/${item.id}/pdf`"
        :filename="`${item.public_id}.pdf`"
        :icon-attrs="{small: true}"
        title="Download PDF"></download-link>
    </template>
  </v-data-table>
</template>

<script>
import DataTable from './DataTable'
import DateField from './DateField'
import DownloadLink from './DownloadLink'
import InvoiceRows from './InvoiceRows'

export default {
  mixins: [DataTable],
  components: {
    DateField,
    DownloadLink,
    InvoiceRows,
  }
}
</script>
<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-combobox v-if="multiple"
        v-model="model"
        :label="label"
        readonly
        multiple
        v-bind="attrs"
        v-on="on"
        :error-messages="errorMessages || []"
      ></v-combobox>
      <v-text-field v-else
        v-model="model"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :error-messages="errorMessages || []"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="model"
      :multiple="multiple"
      :disabled="disabled"
      @input="menu = multiple"
    ></v-date-picker>
  </v-menu>
</template>
<script>

export default {
  props: ["value", "label", "errorMessages", "multiple", "disabled"],
  data () {
    return {
      model: this.value,
      menu: false,
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  }
}
</script>